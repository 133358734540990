import React from "react";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";

const Page = () => {
  return (
      <LessonLayout
        current_step={7}
        lesson={"Lesson 1"}
        color={"hs"}
        total_count={10}
        module_title="Separating the Good from the Bad"
      >
        <ContentWrapper>
          <H2 underline>Step 7</H2>

          <p>Now that you have evaluated the different websites, be prepared to discuss and ask questions about the criteria listed on the rubric and the ones that you added to your rubric.</p>
          <p>You are also now ready to think about a score for website features for each of the 3 sites.</p>
          <p>Your teacher will lead a discussion about how to assign a score on your rubric for each website.</p>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
